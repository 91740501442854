import React, { useState, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss';
import KT from '../images/KT.jpg'

const useStyles = createUseStyles({
  main: {
    backgroundColor: '#384040',
    height: '100vh',
    color: '#fff',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 25
  },
  section: {
    position: 'relative',
    perspective: 1000,
    borderRadius: 5,
    textAlign: 'center',
    flex: 0.2,
    border: '1px solid #fff',
    height: 425,
    transition: 'all ease-in 1s',
    '&:nth-child(odd)': {
      transform: 'translateX(-50%)',
      opacity: 0
    },
    '&:nth-child(even)': {
      transform: 'translateX(50%)',
      opacity: 0
    },
    '&:hover': {
      transition: 'all ease-in 0.5s',
      scale: 1.1
    }
  },
  slideIn: {
    transform: 'translateX(0) !important',
    opacity: '1 !important',
    transition: 'all ease-in 1s',
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  button: {
    flex: 1,
    padding: '12px',
    backgroundColor: '#333',
    color: '#fff',
    border: `1px solid #fff`,
    borderRadius: 5,
    cursor: 'pointer',
  },
  projectTitle: {
    border: '1px solid #fff',
    padding: 10
  },
  projectDescText: {
    padding: 10,
    lineHeight: 1.5,
    textAlign: 'center',
  },
  projectDesc: {
    transform: 'rotateY(180deg)'
  },
  content: {
    position: 'relative',
    width: '100%',
    height: '79%',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.5s',
    '&:hover': {
      transform: 'rotateY(180deg)'
    },
  },
  side: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden', // Hide the back face during rotation
  },
  image: {
    transform: 'rotateY(0deg)',
    '& img': {
      height: 'auto',
      maxWidth: '100%',
      objectFit: 'cover',
    }
  }
});

const Technologies = () => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const ref = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, {
      threshold: 0.5
    });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  const projects = [1, 2, 3, 4];

  return (
    <main ref={ref} className={classes.main}>
      {projects.map(project =>
        <section key={project} className={`${classes.section} ${isIntersecting ? classes.slideIn : ''}`}>
          <h2 className={classes.projectTitle}>Project Title</h2>
          <div className={classes.content}>
            <div className={`${classes.side} ${classes.image}`}>
              <img src={KT} alt="Project Image" />
            </div>
            <div className={`${classes.side} ${classes.projectDesc}`}>
              <p className={classes.projectDescText}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat quasi eligendi ea distinctio maiores, recusandae molestiae voluptatem laborum nesciunt vitae quam dignissimos neque ad suscipit officia. Id accusantium quibusdam quod?</p>
            </div>
          </div>
          <div className={classes.btnWrapper}>
            <button className={classes.button}>View</button>
            <button  className={classes.button}>Sourcecode</button>
          </div>
        </section>
      )}
    </main>
  )
}

export default Technologies