import React from 'react';
import { createUseStyles } from 'react-jss';
import KT from '../images/KT.jpg';
import html from '../images/html.png';
import css from '../images/css.png';
import js from '../images/js.png';
import react from '../images/react.png';
import git from '../images/git.png';
import { easeIn } from 'framer-motion';

const useStyles = createUseStyles({
  main: {
    backgroundColor: 'white',
    height: '100vh',
    color: 'white',
    // position: 'relative',
    overflow: 'hidden',
    // width: '70%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    WebkitMaskImage: 'linear-gradient(90deg, transparent, #fff 20%, #fff 80%, transparent)',
  },
  imageContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // gap: 10,
    // overflow: 'hidden',
    // width: '200%',
    // transition: '1s ease-in-out',
    whiteSpace: 'nowrap',
    animation: '$animate 16s linear infinite'
  },
  '@keyframes animate': {
    '0%': {
      transform: 'translateX(75%)',
    },
    '100%': {
      transform: 'translateX(-75%)',
    },
  },
  image: {
    margin: 10,
    display: 'inline-flex',
    border: '1px solid #fff',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    borderRadius: 10,
    // padding: 20,
    transition: '1s',
    // flex: 1,
    width: 150,
    height: 'auto',
    // marginRight: '10px',
    // flexShrink: 0,
  },
});

const Technologies = () => {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={html} alt="Technology" />
        <img className={classes.image} src={css} alt="Technology" />
        <img className={classes.image} src={js} alt="Technology" />
        <img className={classes.image} src={react} alt="Technology" />
        <img className={classes.image} src={git} alt="Technology" />
        <img className={classes.image} src={html} alt="Technology" />
        <img className={classes.image} src={css} alt="Technology" />
        <img className={classes.image} src={js} alt="Technology" />
        <img className={classes.image} src={react} alt="Technology" />
        <img className={classes.image} src={git} alt="Technology" />
        <img className={classes.image} src={html} alt="Technology" />
        <img className={classes.image} src={css} alt="Technology" />
        <img className={classes.image} src={js} alt="Technology" />
        <img className={classes.image} src={react} alt="Technology" />
        <img className={classes.image} src={git} alt="Technology" />
      </div>
    </main>
  )
}

export default Technologies