import React from 'react'
import ParticlesBackground from './ParticlesBackground'
import TypingEffect from './TypingEffect'
import { createUseStyles } from 'react-jss'
import soon from '../images/soon.png'

const useStyles = createUseStyles({
  home: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  image: {
    width: 100,
    height: 50,
    zIndex: 1,
    position: 'absolute',
    right: 0,
    top: 0,
  }
})
const Home = () => {
  const classes = useStyles();

  return (
      <div className={classes.home}>
        <div>
          <img src={soon} alt="Work in progress" className={classes.image} />
        </div>
        <ParticlesBackground />
        <TypingEffect/>
      </div>
  )
}

export default Home