import React, { useState } from 'react';
import { createUseStyles, ThemeProvider } from 'react-jss';

import Home from './Home';
import Projects from './Projects';
import Technologies from './Technologies';
import ParticlesBackground from './ParticlesBackground';
import Timeline from './Timeline';

const useStyles = createUseStyles((theme) => ({
  button: {
    position: 'absolute',
    // zIndex: 2,
    padding: '10px',
    backgroundColor: theme.background,
    // issue with this
    color: theme.colors?.text,
    border: `1px solid ${theme.accent1}`,
    cursor: 'pointer',
  }
}));

  const lightTheme = {
    colors: {
      // background: '#001f3f',
      // text: '#ffffff',
      // accent1: '#3498db',
      // accent2: '#27ae60',
      // particleJS: '#27ae60',
      background: '#dee4e7',
      text: '#333333',
      accent1: '#3498db',
      accent2: '#27ae60',
      particleJS: '#333333',
    }
};

  const darkTheme = {
    colors: {
      // background: '#2C3E50',
      // text: '#ecf0f1',
      // accent1: '#e74c3c',
      // accent2: '#f39c12',
      // particleJS: '#ecf0f1',
      background: '#222222',
      text: '#ffffff',
      accent1: '#e74c3c',
      accent2: '#f39c12',
      particleJS: '#ffffff',
    }
  };

function App() {
  
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isOn, setOn] = useState(false);
  const classes = useStyles();

  const handleToggle = () => {
    setOn((prev) => !prev);
  };
  
  return (
    // <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
    //   <div className={classes.app}>
    //     <button
    //       className={classes.button}
    //       onClick={() => setIsDarkMode(prevState => !prevState)}
    //     >
    //       Toggle Theme
    //     </button>
    //     <header>
    //         <ParticlesBackground isDarkMode={isDarkMode} darkTheme={darkTheme} lightTheme={lightTheme} />
    //       <TypingEffect/>
    //     </header>
    //   <footer></footer>
    //  </div> 
    // </ThemeProvider>
    <ThemeProvider theme={darkTheme}>
      <Home />
      {/* <Projects /> */}
      {/* <Technologies /> */}
      {/* <Timeline/> */}
    </ThemeProvider>
  );
}

export default App;
