import React, { useEffect } from 'react'

import { createUseStyles } from 'react-jss';
import Typed from "typed.js";

const useStyles = createUseStyles(theme => ({
  typingEffect: {
    userSelect: 'none',
    fontSize: '2rem',
    position: 'absolute',
    textAlign: 'center',
  },
  nameWrapper: {
    color: '#2a2',
    lineHeight: '4rem',
  },
  name: {
    display: 'inline-block',
  },
  role: {
    color: '#2a2',
    marginTop: '5px'
  },
  '@media (min-width:320px)': {
    typingEffect: {
      fontSize: '1rem',
    }
  },
  '@media (min-width:641px)': {
    typingEffect: {
      fontSize: '1.5rem',
    }
  },
}));

const TypingEffect = () => {
  const classes = useStyles();
  const el = React.useRef(null);
  const typed = React.useRef(null);

  useEffect(() => {
    const options = {
      strings: [
        "RAMA",  // English
        "రామ",  // Telugu
        "ராம",   // Tamil
        "ರಾಮ",  // Kannada
        "राम",    // Hindi
        "രാമ",   // Malayalam
        "রাম",   // Bengali
      ],
      typeSpeed: 10,
      backSpeed: 25,
      backDelay: 1000,
      showCursor: true,
      cursorChar: "|",
      loop: true,
    };
    typed.current = new Typed(el.current, options);
    return () => {
      typed.current.destroy();
    }
  }, []);

  return (
    <div className={classes.typingEffect}>
        <h1 className={classes.nameWrapper}>
        <span>{"HI, I'M "}</span>
          <span className={classes.name} ref={el} />
        </h1>
        <h2 className={classes.role}>A WEB DEVELOPER</h2>
    </div>
  )
}

export default TypingEffect;